import { Typography } from '@material-tailwind/react'
import React from 'react'
import { ActivitiesTimeline } from '../timeline/ActivitiesTimeline'
import RevealOnScrollFadeRight from '../animation/ScrollFadeRight'

export default function Mindset() {
  return (
    <div className='flex flex-col sm:flex-row gap-4 px-6 lg:px-20 justify-center bg-primary py-6 sm:py-20'>
        <div className='flex flex-col w-full lg:w-1/2 gap-2 sm:gap-0'>
            <RevealOnScrollFadeRight>
                <Typography className='text-4xl lg:text-7xl font-bold'>
                    Lab Mindset Approach: Driving Innovation Through Four Pillars
                </Typography>
            </RevealOnScrollFadeRight>
            <RevealOnScrollFadeRight>
                <Typography color="blue-gray">
                    At <span className='font-bold'>365Labs, Inc.</span>, we embrace a unique <span className='font-bold'>"lab mindset"</span> approach, which guides our every endeavor. This approach is built on four core pillars:
                </Typography>
            </RevealOnScrollFadeRight>
        </div>
        <div className='flex col mt-4 sm:mt-0'>
            <ActivitiesTimeline/>
        </div>
    </div>
  )
}
