import {
    Card,
    CardHeader,
    CardBody,
    Typography,
  } from "@material-tailwind/react";
   
  export function CardDefault({item}) {
    return (
      <Card className="mt-6 h-[420px] lg:h-[450px]">
        <div shadow={false} className="bg-primary p-10 relative rounded-tr-lg rounded-tl-lg gap-1 flex items-center justify-center">
          {
            item.src.map((logo) => (
              <img src={logo} alt="businesscentral" className="h-24 object-contain"/>
            ))
          }
        </div>
        <CardBody>
          <Typography variant="h5" color="blue-gray" className="mb-2">
            {item.title}
          </Typography>
          <Typography>
            {item.message}
          </Typography>
        </CardBody>
      </Card>
    );
  }