import React from 'react'
import RevealOnScrollFadeLeft from '../animation/ScrollFadeLeft'
import user from '../../assets/user.png'

export default function Blogs({blogRef}) {
    return (
        <section className="flex flex-col sm:flex-row gap-4 sm:gap-0 px-4 sm:px-10 py-11 sm:py-20" ref={blogRef}>
            <div className="text-center flex justify-center items-center">
                <RevealOnScrollFadeLeft>
                    <h1 className="text-4xl font-bold text-primary font-primary">Blogs</h1>
                    <div className="px-4 leading-8">
                        <p className="text-md text-justify">
                            Welcome to our tech-centric blog, where we unravel the dynamic world of
                            cutting-edge solutions and digital innovation. Dive into insightful articles
                            that explore the latest trends in software development, cloud computing,
                            cybersecurity, and data analytics.{" "}
                        </p>
                    </div>
                </RevealOnScrollFadeLeft>
            </div>
            <div className='flex flex-col justify-center'>
                <RevealOnScrollFadeLeft>
                    <div className='shadow-md gap-4 flex flex-col justify-center p-10'>
                        <h1 className='text-2xl font-semibold'>Revolutionizing Business Central: An Enterprise Resource Planning Journey</h1>
                        <p className='leading-6 text-gray-700 text-justify'>In today's dynamic digital landscape, a robust online presence is the
                            cornerstone of success for businesses centralizing their operations. Join us on
                            an exciting voyage through the realms of web development tailored specifically
                            for Business Central. From sleek user interfaces to seamless integration, we're
                            redefining the digital frontier for your enterprise. Let's embark on this
                            transformative journey together and elevate your business to new heights.</p>
                        <div className='flex flex-row items-center gap-2'>
                            <div>
                                <img src={user} className='object-contain w-6' alt='user'/>
                            </div>
                            <div className='flex flex-col items-center'>
                                <p>John Francis</p>
                            </div>
                            <div className='flex flex-col items-center'>
                                <p className='text-sm text-gray-600'>On March 01, 2024</p>
                            </div>
                        </div>
                    </div>
                </RevealOnScrollFadeLeft>
            </div>
        </section>
    )
}
