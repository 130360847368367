import {
    Timeline,
    TimelineItem,
    TimelineConnector,
    TimelineIcon,
    Typography,
    TimelineHeader
} from "@material-tailwind/react";
import {BellIcon, CogIcon, UserGroupIcon, DocumentMagnifyingGlassIcon} from "@heroicons/react/24/solid";
import agile from '../../assets/icons8-agile-64.png'
import RevealOnScrollFadeLeft from "../animation/ScrollFadeLeft";

export function ActivitiesTimeline() {
    return (
        <div className="w-[25rem]">
            <Timeline>
                <TimelineItem className="h-52 sm:h-44">
                    <TimelineConnector className="!w-[78px]"/>
                    <RevealOnScrollFadeLeft>
                        <TimelineHeader
                            className="relative rounded-xl border border-blue-gray-50 bg-white py-3 pl-4 pr-8 shadow-lg shadow-blue-gray-900/5">
                            <TimelineIcon className="p-3" variant="ghost">
                                <img src={agile} alt="agile" className="h-5 w-5"/>
                            </TimelineIcon>
                            <div className="flex flex-col gap-1">
                                <Typography variant="h6" color="blue-gray">
                                    Agile Development
                                </Typography>
                                <Typography variant="small" color="gray" className="font-normal  text-justify">
                                    We believe in rapid iteration, continuous improvement, and adaptive development
                                    methodologies that allow us to respond swiftly to changing business needs.
                                </Typography>
                            </div>
                        </TimelineHeader>
                    </RevealOnScrollFadeLeft>
                </TimelineItem>
                <TimelineItem className="h-[10.5rem] sm:h-[9.5rem]">
                    <TimelineConnector className="!w-[78px]"/>
                    <RevealOnScrollFadeLeft>
                        <TimelineHeader
                            className="relative rounded-xl border border-blue-gray-50 bg-white py-3 pl-4 pr-8 shadow-lg shadow-blue-gray-900/5">
                            <TimelineIcon className="p-3" variant="ghost" color="black">
                                <DocumentMagnifyingGlassIcon className="h-5 w-5"/>
                            </TimelineIcon>
                            <div className="flex flex-col gap-1">
                                <Typography variant="h6" color="blue-gray">
                                    Test and Learn Approach
                                </Typography>
                                <Typography variant="small" color="gray" className="font-normal  text-justify">
                                    Through rigorous testing and iterative learning cycles, we refine our solutions
                                    to ensure optimal performance and effectiveness.
                                </Typography>
                            </div>
                        </TimelineHeader>
                    </RevealOnScrollFadeLeft>
                </TimelineItem>
                <TimelineItem className="h-[13.5rem] sm:h-[12.5rem]">
                    <TimelineConnector className="!w-[78px]"/>
                    <RevealOnScrollFadeLeft>
                        <TimelineHeader
                            className="relative rounded-xl border border-blue-gray-50 bg-white py-3 pl-4 pr-8 shadow-lg shadow-blue-gray-900/5">
                            <TimelineIcon className="p-3" variant="ghost" color="black">
                                <UserGroupIcon className="h-5 w-5"/>
                            </TimelineIcon>
                            <div className="flex flex-col gap-1">
                                <Typography variant="h6" color="blue-gray">
                                    Collaboration and Cross-Disciplinary Teams
                                </Typography>
                                <Typography variant="small" color="gray" className="font-normal  text-justify">
                                    We foster a culture of collaboration, bringing together diverse talents and
                                    expertise (industry and technology-wise) to deliver comprehensive and impactful
                                    solutions.
                                </Typography>
                            </div>
                        </TimelineHeader>
                    </RevealOnScrollFadeLeft>
                </TimelineItem>
                <TimelineItem className="h-44">
                    <RevealOnScrollFadeLeft>
                        <TimelineHeader
                            className="relative rounded-xl border border-blue-gray-50 bg-white py-3 pl-4 pr-8 shadow-lg shadow-blue-gray-900/5">
                            <TimelineIcon className="p-3" variant="ghost" color="black">
                                <CogIcon className="h-5 w-5"/>
                            </TimelineIcon>
                            <div className="flex flex-col gap-1">
                                <Typography variant="h6" color="blue-gray">
                                    Prototyping and Innovation
                                </Typography>
                                <Typography variant="small" color="gray" className="font-normal  text-justify">
                                    Our commitment to prototyping and innovation drives us to explore new ideas,
                                    technologies, and approaches, pushing the boundaries of what's possible in
                                    digital transformation.
                                </Typography>
                            </div>
                        </TimelineHeader>
                    </RevealOnScrollFadeLeft>
                </TimelineItem>
            </Timeline>
        </div>
    );
}