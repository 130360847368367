import {Typography} from '@material-tailwind/react'
import React from 'react'
import RevealOnScrollFadeRight from '../animation/ScrollFadeRight'
import RevealOnScrollFadeLeft from '../animation/ScrollFadeLeft'

export default function Transformation() {
    return (
        <div className='flex flex-col lg:flex-row p-4 sm:p-10'>
            <div className='w-full lg:w-1/2 p-2 sm:p-8 flex flex-col gap-3'>
                <RevealOnScrollFadeRight>
                    <Typography className='text-4xl lg:text-6xl font-bold'>
                        Experience the Profound Ripple Effect of Transformation
                    </Typography>
                </RevealOnScrollFadeRight>
                <RevealOnScrollFadeRight>
                    <Typography>
                        By partnering with <span className='font-bold'>365Labs, Inc.</span>, you'll witness the profound ripple effect of
                        digital transformation across your organization. From improved efficiencies to
                        enhanced decision-making capabilities, our solutions have a lasting impact that
                        drives growth, innovation, and success.
                    </Typography>
                </RevealOnScrollFadeRight>
            </div>
            <div className='w-full lg:w-1/2'>
                <RevealOnScrollFadeLeft>
                    <img src='https://images.pexels.com/photos/212286/pexels-photo-212286.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1' alt='banner'/>
                </RevealOnScrollFadeLeft>
            </div>
        </div>
    )
}
