import React from 'react'
import RevealOnScrollFadeRight from '../animation/ScrollFadeRight';
import aboutus1 from '../../assets/aboutus1.jpg';
import aboutus2 from '../../assets/aboutus2.jpg';
import aboutus3 from '../../assets/aboutus3.jpg';

export default function ImageContainer2() {
    return (
        <div className="flex flex-col sm:flex-row gap-0 sm:gap-2 mb-16 mt-3">
            <div className="flex-1">
                <RevealOnScrollFadeRight>
                    <img
                        src={aboutus1}
                        className="h-full w-full cursor-pointer animate-rotate-y"
                        alt="Img 1"/>
                </RevealOnScrollFadeRight>
            </div>
            <div className="flex-1">
                <RevealOnScrollFadeRight>
                    <img
                        src={aboutus2}
                        className="h-full w-full cursor-pointer animate-rotate-y"
                        alt="Img 2"/>
                </RevealOnScrollFadeRight>
            </div>
            <div className="flex-1">
                <RevealOnScrollFadeRight>
                    <img
                        src={aboutus3}
                        className="h-full w-full cursor-pointer animate-rotate-y"
                        alt="Img 3"/>
                </RevealOnScrollFadeRight>
            </div>
        </div>
    )
}
