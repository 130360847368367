import React, {useEffect, useRef, useState} from "react";
import RevealOnScrollFadeLeft from "../animation/ScrollFadeLeft";
import image1 from '../../assets/business_centra.png'
import image2 from '../../assets/financeoperation.png'
import image3 from '../../assets/lsretail.png'
import RevealOnScrollFadeRight from "../animation/ScrollFadeRight";
import microsoft from '../../assets/microsoft.png'
import { Typography } from "@material-tailwind/react";

export default function OurServices({servicesRef}) {
    return (
        <section className="py-14 px-0 sm:px-4" ref={servicesRef}>
            <div className="flex flex-col gap-1 justify-center items-center">
                <Typography variant='h1' className='text-center text-primary'>Our Technology Partners</Typography>
                <div className="flex flex-col sm:flex-row gap-4 items-center">
                    <img src={microsoft} alt="microsoft" className="object-contain h-20"/>
                    <img
                        src="https://portal.lsretail.com/media/rprnhqo0/ls-retail-an-aptos-company-logo.png?width=364&height=150&mode=max"
                        className="object-contain h-22"
                        alt="microsoft"/>
                </div>
            </div>
            {/* <div className="text-center">
                <RevealOnScrollFadeLeft>
                    <h1 className="text-4xl font-bold mt-10 mb-4 text-primary font-primary">Our Services</h1>
                    <div className="px-4 lg:px-56 leading-8 flex flex-col gap-4">
                        <p className="text-md text-justify">
                            <span className="text-primary font-bold">LS Retail</span> offers a comprehensive retail management solution designed to
                            streamline operations and enhance customer experiences. Key features include a
                            point-of-sale system, inventory management, customer relationship management,
                            and sales analysis tools. Benefits include streamlined operations, enhanced
                            customer experiences, and increased sales. <span className="text-primary font-bold">LS Retail</span> is suitable for retail
                            businesses of all sizes and industries. Implementation and support services are
                            available, and success stories highlight its effectiveness. Partnership
                            opportunities are also offered.
                        </p>
                        <p className="text-md text-justify">
                        <span className="text-primary font-bold">LS Express</span> is a simplified version of LS Retail tailored for small and
                            medium-sized businesses (SMBs). It offers essential retail management
                            functionalities in a user-friendly interface, including point-of-sale (POS),
                            inventory management, and sales reporting. <span className="text-primary font-bold">LS Express</span> is cost-effective, easy to
                            implement, and designed to improve retail operations for SMBs. It's suitable for
                            small and medium-sized retail businesses seeking a straightforward solution to
                            manage their operations efficiently.
                        </p>
                        <p className="text-md text-justify">
                        <span className="text-primary font-bold">Dynamics 365 Business Central</span> is a cloud-based enterprise resource planning
                            (ERP) solution designed to help businesses manage finances, operations, sales,
                            and customer service. It offers modules for financial management, supply chain
                            management, project management, sales, and more. <span className="text-primary font-bold">Business Central</span> is highly
                            customizable and scalable, allowing businesses to adapt and grow according to
                            their needs. It's suitable for businesses of all sizes looking for a
                            comprehensive ERP solution to streamline processes and improve efficiency.
                        </p>
                        <p className="text-md text-justify">
                        <span className="text-primary font-bold">Dynamics 365 Finance and Operations</span> is an advanced enterprise resource planning
                            (ERP) solution that provides comprehensive financial management, manufacturing,
                            supply chain management, human resources, and more. It is designed for large and
                            multinational organizations with complex operational needs. The solution helps
                            streamline processes, optimize resource utilization, and drive operational
                            excellence. <span className="text-primary font-bold">Dynamics 365 Finance and Operations</span> is highly customizable and
                            scalable, making it suitable for organizations seeking to manage complex
                            financial and operational requirements efficiently.
                        </p>
                    </div>
                </RevealOnScrollFadeLeft>
            </div> */}
            {/* <RevealOnScrollFadeRight>
                <div
                    className="flex flex-col sm:flex-row gap-4 justify-center items-center mt-4">
                    <img src={image1} alt="businesscentral" className="w-[200px] object-contain"/>
                    <img
                        src={image2}
                        alt="finance and operation"
                        className="w-[200px] object-contain"/>
                    <img src={image3} alt="lsretail" className="w-[200px] object-contain"/>
                </div>
            </RevealOnScrollFadeRight> */}
        </section>
    );
}
