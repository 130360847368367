import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import {ThemeProvider} from "@material-tailwind/react";
import { ToastContainer } from 'react-toastify';
import App from './App';

import "react-toastify/dist/ReactToastify.min.css";
import './index.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
        <ThemeProvider>
            <App/>
            <ToastContainer />
        </ThemeProvider>
);

// If you want to start measuring performance in your app, pass a function to
// log results (for example: reportWebVitals(console.log)) or send to an
// analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
