import { Button, Input, Textarea, Typography } from '@material-tailwind/react';
import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useLottie } from 'lottie-react';
import contactus from '../../assets/contactus.json';
import RevealOnScrollFadeLeft from '../animation/ScrollFadeLeft';
import { toast } from 'react-toastify';

export default function ContactUs({ contactusRef }) {
    const options = {
        animationData: contactus,
        loop: true
    };

    const [loading, setLoading] = useState(false)

    const { View } = useLottie(options);

    // Formik form validation schema
    const validationSchema = Yup.object().shape({
        firstname: Yup.string().required('First Name is required'),
        lastname: Yup.string().required('Last Name is required'),
        phone: Yup.string().required('Phone number is required'),
        email: Yup.string().email('Invalid email address').required('Email is required'),
        message: Yup.string().required('Message is required')
    });

    // Formik form submission and handling
    const formik = useFormik({
        initialValues: {
            firstname: '',
            lastname: '',
            phone: '',
            email: '',
            message: ''
        },
        validationSchema,
        onSubmit: values => {
            setLoading(true)
            handleSubmit(values)
        }
    });

    const handleSubmit = async (values) => {
        try {
          await fetch('https://365labsinc-ticketing-func-dev.azurewebsites.net/api/inquiry', {
            method: 'POST',
            mode : 'no-cors',
            headers: {
              'Content-Type': 'application/json'
              
            },
            body: JSON.stringify(values)
          });
          toast.success("Message successfully sent. Thank you!");
          setLoading(false);
          formik.handleReset()
        } catch (error) {
            toast.error("Unable to complete action. Please try again later.");
            setLoading(false);
        }
    };

    return (
        <section className='pb-0 sm:pb-20 py-15 bg-gradient-to-b from-white to-primary/20' ref={contactusRef}>
            <RevealOnScrollFadeLeft>
                <div className='flex flex-col sm:flex-row px-8 w-full md:w-[80vw] lg:w-1/2 mx-auto bg-white py-10 rounded-lg'>
                    <div className='w-full sm:w-2/3 flex flex-col gap-3'>
                        <Typography variant='h5' className='text-primary font-primary text-3xl'>
                            <h1>Get in Touch</h1>
                        </Typography>
                        <Typography variant='p' className='font-secondary'>
                            <p>You can reach us anytime</p>
                        </Typography>
                        <form onSubmit={formik.handleSubmit}>
                            <div className='flex flex-col gap-2'>
                                <div className='flex flex-col sm:flex-row gap-2'>
                                    <div className='w-full'>
                                        <Input
                                            label='First Name'
                                            name='firstname'
                                            value={formik.values.firstname}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                        />
                                        {formik.touched.firstname && formik.errors.firstname ? (
                                            <div className='text-red-500 text-sm'>{formik.errors.firstname}</div>
                                        ) : null}
                                    </div>
                                    <div className='w-full'>
                                        <Input
                                            label='Last Name'
                                            name='lastname'
                                            value={formik.values.lastname}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                        />
                                        {formik.touched.lastname && formik.errors.lastname ? (
                                            <div className='text-red-500 text-sm'>{formik.errors.lastname}</div>
                                        ) : null}
                                    </div>
                                </div>
                                <div>
                                    <Input
                                        type='tel'
                                        label='Phone'
                                        name='phone'
                                        value={formik.values.phone}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                    {formik.touched.phone && formik.errors.phone ? (
                                        <div className='text-red-500 text-sm'>{formik.errors.phone}</div>
                                    ) : null}
                                </div>
                                <div>
                                    <Input
                                        type='email'
                                        label='Email'
                                        name='email'
                                        value={formik.values.email}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                    {formik.touched.email && formik.errors.email ? (
                                        <div className='text-red-500 text-sm'>{formik.errors.email}</div>
                                    ) : null}
                                </div>
                                <div>
                                    <Textarea
                                        label='Message'
                                        name='message'
                                        value={formik.values.message}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                    {formik.touched.message && formik.errors.message ? (
                                        <div className='text-red-500 text-sm'>{formik.errors.message}</div>
                                    ) : null}
                                </div>
                                <div className='flex gap-2 justify-end'>
                                    <Button loading={loading} type='submit' size='sm' className='bg-primary font-primary rounded-md'>
                                        Send Message
                                    </Button>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className='w-full flex flex-col justify-end items-center sm:w-1/2 relative'>
                        <div className='mr-[-66px] w-full'>{View}</div>
                    </div>
                </div>
            </RevealOnScrollFadeLeft>
        </section>
    );
}
