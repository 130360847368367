import {Typography} from '@material-tailwind/react'
import React from 'react'
import {CardDefault} from '../cards/CardDefault'
import businesscentral from '../../assets/svg/BusinessCentral_scalable.svg'
import finance from '../../assets/svg/Finance+Operations_scalable.svg'
import supply from '../../assets/svg/SupplyChainManagement_scalable.svg'
import customerengagement from '../../assets/svg/CustomerService_scalable.svg'
import RevealOnScrollFadeRight from '../animation/ScrollFadeRight'
import RevealOnScrollFadeLeft from '../animation/ScrollFadeLeft'

const solutions = [
    {
        title: 'Dynamics 365 Business Central',
        src: [businesscentral],
        message: 'Streamline your financials, operations, and supply chain management with the pow' +
                'er of Dynamics 365 Business Central.'
    }, {
        title: 'Dynamics 365 Finance and Supply Chain Management',
        src: [
            finance, supply
        ],
        message: 'Optimize your financial processes and supply chain operations for enhanced effic' +
                'iency and profitability.'
    }, {
        title: 'Dynamics 365 Customer Engagement',
        src: [customerengagement],
        message: 'Build lasting customer relationships and drive business growth with Dynamics 365' +
                ' Customer Engagement.'
    }, {
        title: 'LS Express',
        src: ['https://www.lsretail.com/hubfs/s2_assets/logo/ls-retail-logo-white.svg'],
        message: 'Leverage LS Express for retail-specific solutions that enhance inventory managem' +
                'ent, sales processes, and customer interactions.'
    }, {
        title: 'Power Platform (Power BI, Power Apps, Power Automate)',
        src: ['https://decisioninc.com/wp-content/uploads/2022/10/microsoft_power_platform_icon' +
                '_2022.png'],
        message: 'Harness the full potential of data analytics, app development, and workflow auto' +
                'mation with the Microsoft Power Platform.'
    }, {
        title: 'Shopify Integration with Dynamics 365 Business Central',
        src: [businesscentral],
        message: 'Seamlessly integrate your Shopify e-commerce platform with Dynamics 365 Business' +
                ' Central for streamlined operations and improved customer experiences.'
    }
]
export default function Solutions({solutionRef}) {
    return (
        <div ref={solutionRef} className='px-4 sm:px-10 py-12 flex flex-col gap-2 '>
            <RevealOnScrollFadeLeft>
                <Typography variant='h1' className='text-center text-primary'>Our Solutions</Typography>
            </RevealOnScrollFadeLeft>
            <div className='grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-4'>
                {solutions.map((item, index) => (
                    <RevealOnScrollFadeRight>
                        <CardDefault key={index} item={item}/>
                    </RevealOnScrollFadeRight>
                ))
}
            </div>
        </div>
    )
}
