import { useEffect, useRef, useState } from "react";

const RevealOnScrollFadeLeft = ({ children }) => {
    const [isVisible, setIsVisible] = useState(false);
    const ref = useRef(null);
  
    useEffect(() => {
        const onWindScroll = () => {
            const element = ref.current;
            if (element) {
                const { top } = element.getBoundingClientRect();
                const isVisible = top < window.innerHeight;
                setIsVisible(isVisible);
            }
        };
  
        window.addEventListener("scroll", onWindScroll);
        return () => {
            window.removeEventListener("scroll", onWindScroll);
        };
    }, []);
  
    const classes = `
        ${isVisible ? "animate-fade-left animate-duration-[2000ms]" : ""
        }`;
  
    return (
        <div ref={ref} className={classes}>
            {children}
        </div>
    );
  };


export default RevealOnScrollFadeLeft;