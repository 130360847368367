import React, { useRef, useEffect, useState } from 'react';
import SimpleFooter from './components/footer/SimpleFooter';
import SimpleNavbar from './components/navbar/SimpleNavbar';
import OurServices from './components/content/OurServices';
import Blogs from './components/content/Blogs';
import AboutUs from './components/content/AboutUs';
import { CarouselWithContent } from './components/carousel/CarouselWithContent';
import Vision from './components/content/Vision';
import Mission from './components/content/Mission';
import Mindset from './components/content/Mindset';
import Solutions from './components/content/Solutions';
import WhyUs from './components/content/WhyUs';
import Clients from './components/content/Clients';
import Transformation from './components/content/Transformation';
import CallToAction from './components/content/CallToAction';
import ContactUs from './components/content/ContactUs';

export default function App() {
  const [sectionVisibility, setSectionVisibility] = useState({});
  const [isMobile, setIsMobile] = useState(false);
  
  useEffect(() => {
    function handleResize() {
      setIsMobile(window.innerWidth < 768); // Adjust this threshold according to your definition of "mobile"
    }

    // Initial check on mount
    handleResize();

    // Add event listener to window resize
    window.addEventListener('resize', handleResize);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const refs = {
    servicesRef: useRef(null),
    blogRef: useRef(null),
    homeRef: useRef(null),
    aboutUsRef: useRef(null),
    contactusRef: useRef(null),
    solutionRef: useRef(null),
  };

  const scrollToRef = (ref) => {
    ref.current.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
  };

  const handleScroll = () => {
    const visibility = {};
    for (const key in refs) {
      if (refs.hasOwnProperty(key)) {
        const ref = refs[key];
        const rect = ref.current.getBoundingClientRect();
        visibility[key] = rect.top >= (isMobile ? - 0 : -200) && rect.bottom <= (window.innerHeight - (isMobile ? - 0 : -200));
      }
    }
    setSectionVisibility(visibility);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div className='min-h-screen w-full'>
      <SimpleNavbar 
        scrollServices={() => scrollToRef(refs.servicesRef)} 
        scrollToBlogs={() => scrollToRef(refs.blogRef)} 
        scrollToAboutUs={() => scrollToRef(refs.aboutUsRef)}
        sectionVisibility={sectionVisibility}
        scrollToContactUs={() => scrollToRef(refs.contactusRef)}
        scrollToSolution={() => scrollToRef(refs.solutionRef)}
      />
      <CarouselWithContent homeRef={refs.homeRef} scrollToContactUs={() => scrollToRef(refs.contactusRef)} scrollServices={() => scrollToRef(refs.servicesRef)}/>
      {/* <Mission/>
      <Vision /> */}
      {/* <ImageContainer/> */}
      <AboutUs aboutUsRef={refs.aboutUsRef}/>
      {/* <Clients/> */}
      {/* <Team/> */}
      <Mindset/>
      <Solutions solutionRef={refs.solutionRef}/>
      <OurServices servicesRef={refs.servicesRef} />
      <WhyUs/>
      <Blogs blogRef={refs.blogRef} />
      <Transformation/>
      <ContactUs contactusRef={refs.contactusRef}/>
      <SimpleFooter/>
    </div>
  )
}
