import React, {useEffect, useState} from "react";
import {Navbar, MobileNav, Typography, IconButton} from "@material-tailwind/react";
import samplelogo from "../../assets/365logo.jpg"

export default function SimpleNavbar({scrollServices, scrollToBlogs, 
    scrollToAboutUs, sectionVisibility, scrollToContactUs, scrollToSolution}) {
    const [navbarPosition,
        setNavbarPosition] = useState("absolute");
    const [openNav,
        setOpenNav] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const offset = window.scrollY;
            if (offset > 0) {
                setNavbarPosition("sticky");
            } else {
                setNavbarPosition("absolute");
            }
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const handleClickServices = () => {
        if (typeof scrollServices === 'function') {
            scrollServices();
        }
    };

    const handleClickBlogs = () => {
        if (typeof scrollToBlogs === 'function') {
            scrollToBlogs();
        }
    };

    const handleContact = () => {
        if (typeof scrollToContactUs === 'function') {
            scrollToContactUs();
        }
    };

    const handleClickAboutUs = () => {
        if (typeof scrollToAboutUs === 'function') {
            scrollToAboutUs();
        }
    };

    const handleClickSolutions = () => {
        if (typeof scrollToSolution === 'function') {
            scrollToSolution();
        }
    };


    useEffect(() => {
        window.addEventListener("resize", () => window.innerWidth >= 960 && setOpenNav(false),);
    }, []);

    const navList = (
        <ul
            className="mt-2 mb-4 flex flex-col gap-2 lg:mb-0 lg:mt-0 lg:flex-row lg:items-center lg:gap-6">
            <Typography
                as="li"
                variant="small"
                color="blue-gray"
                className={`p-1 font-normal ${(sectionVisibility?.servicesRef && !sectionVisibility?.blogRef) && 'text-primary font-normal border-b-2 border-primary'}`}>
                <p
                    onClick={handleClickServices}
                    className="flex items-center animate-fade-down cursor-pointer font-secondary">
                    Services
                </p>
            </Typography>
            <Typography
                as="li"
                variant="small"
                color="blue-gray"
                className={`p-1 font-normal ${sectionVisibility?.aboutUsRef && 'text-primary font-normal border-b-2 border-primary'}`}>
                <p
                    onClick={handleClickAboutUs}
                    className="cursor-pointer flex items-center animate-fade-down font-secondary">
                    About Us
                </p>
            </Typography>
            <Typography
                as="li"
                variant="small"
                color="blue-gray"
                className={`p-1 font-normal ${(sectionVisibility?.solutionRef && !sectionVisibility?.servicesRef) && 'text-primary font-normal border-b-2 border-primary'}`}>
                <p
                    onClick={handleClickSolutions}
                    className="cursor-pointer flex items-center animate-fade-down font-secondary">
                    Our Solutions
                </p>
            </Typography>
            <Typography
                as="li"
                variant="small"
                color="blue-gray"
                className={`p-1 font-normal ${(sectionVisibility?.blogRef && !sectionVisibility?.servicesRef) && 'text-primary font-normal border-b-2 border-primary'}`}>
                <p
                    onClick={handleClickBlogs}
                    className="cursor-pointer flex items-center animate-fade-down font-secondary">
                    Blog
                </p>
            </Typography>
            <Typography
                as="li"
                variant="small"
                color="blue-gray"
                className={`p-1 font-normal ${(sectionVisibility?.contactusRef && !sectionVisibility?.servicesRef) && 'text-primary font-normal border-b-2 border-primary'}`}>
                <p
                    onClick={handleContact}
                    className="cursor-pointer flex items-center animate-fade-down font-secondary">
                    Contact Us
                </p>
            </Typography>
        </ul>
    );

    return (
        <Navbar
            className={`${navbarPosition === "absolute" ? "absolute top-0 z-50" : "sticky top-0 z-50"} h-max max-w-full rounded-none px-4 py-2 lg:px-8 lg:py-4`}>
            <div
                className="flex items-center justify-between text-blue-gray-900 w-full sm:w-[50vw] mx-auto">
                <div className="flex items-center gap-4">
                    <img
                        className="h-10 rounded-full object-cover object-center animate-jump-in"
                        src={samplelogo}
                        alt="logo"/>
                </div>
                <div className="flex items-center gap-4">
                    <div className="mr-4 hidden lg:block">{navList}</div>
                    <IconButton
                        variant="text"
                        className="ml-auto h-6 w-6 text-inherit hover:bg-transparent focus:bg-transparent active:bg-transparent lg:hidden"
                        ripple={false}
                        onClick={() => setOpenNav(!openNav)}>
                        {openNav
                            ? (
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    className="h-6 w-6"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    strokeWidth={2}>
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12"/>
                                </svg>
                            )
                            : (
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-6 w-6"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth={2}>
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16M4 18h16"/>
                                </svg>
                            )}
                    </IconButton>
                </div>
            </div>
            <MobileNav open={openNav}>
                {navList}
            </MobileNav>
        </Navbar>
    )
}
