import React from "react";
import RevealOnScrollFadeLeft from "../animation/ScrollFadeLeft";
import ImageContainer2 from "./ImageContainer2";
import { Typography } from "@material-tailwind/react";

export default function AboutUs({aboutUsRef}) {
    return (
        <section className="px-0 sm:px-12" ref={aboutUsRef}>
            <ImageContainer2/>
            <div className="text-center mb-10">
                <RevealOnScrollFadeLeft>
                    <Typography variant='h1' className='text-center text-primary'>About Us</Typography>
                    <div className="leading-8">
                        <p className="text-lg font-bold px-4 sm:px-56 mb-4">
                            Trust, Talent and Transparency as Cornerstones of Digital Transformation
                        </p>
                        <p className="text-md px-4 lg:px-56 text-justify">
                            At
                            <strong className="font-primary text-primary"> 365Labs, Inc. </strong>, we believe
                            in a world where Trust, Talent and Transparency are not just ideals but the very
                            foundation of digital evolution. Our <strong className="font-primary text-primary">mission</strong> is to revolutionize the way
                            businesses establish and maintain trust in today's fast-paced digital landscape.
                            With a firm commitment to empowering individuals and organizations, we offer
                            innovative solutions and expertise that promote trust, facilitate transparency,
                            and drive scalable and agile implementation across various business sectors.
                        </p>
                    </div>
                </RevealOnScrollFadeLeft>
            </div>
        </section>
    );
}