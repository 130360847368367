import {Card, Typography} from '@material-tailwind/react'
import React from 'react'
import logo from '../../assets/365footer.png'
import RevealOnScrollFadeLeft from '../animation/ScrollFadeLeft'
import RevealOnScrollRotate from '../animation/ScrollRotateY'

export default function WhyUs() {
    return (
        <div className='p-4 sm:p-10'>
            <RevealOnScrollFadeLeft>
                <Typography variant='h1' className='text-center text-primary'>Why Choose 365Labs, Inc.?</Typography>
            </RevealOnScrollFadeLeft>
            <div className='flex justify-center items-center mb-10'>
                <RevealOnScrollRotate>
                    <img src={logo} alt='logo' className='h-24 object-contain rounded-full'/>
                </RevealOnScrollRotate>
            </div>
            <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4'>
                <Card
                    className='rounded-[2rem] p-8 flex justify-center items-center gap-2 bg-primary'>
                    <Typography variant='h4' color='white'>
                        Trusted Expertise
                    </Typography>
                    <Typography variant='small' className='text-justify text-white '>
                        With 8 years of experience and a track record of successful implementations and
                        client retention, we are your trusted partner for all your digital
                        transformation needs.
                    </Typography>
                </Card>
                <Card
                    className='rounded-[2rem] p-8 flex justify-center items-center gap-2 bg-primary'>
                    <Typography variant='h4' color='white'>
                        Innovative Solutions
                    </Typography>
                    <Typography variant='small' className='text-justify text-white'>
                        We stay ahead of the curve by embracing the latest technologies and delivering
                        innovative solutions that drive tangible results.
                    </Typography>
                </Card>
                <Card
                    className='rounded-[2rem] p-8 flex justify-center items-center gap-2 bg-primary'>
                    <Typography variant='h4' color='white'>
                        Client-Centric Approach
                    </Typography>
                    <Typography variant='small' className='text-justify text-white'>
                        Our focus is always on understanding your unique challenges and delivering
                        tailored solutions that align with your business objectives.
                    </Typography>
                </Card>
                <Card
                    className='rounded-[2rem] p-8 flex justify-center items-center gap-2 bg-primary'>
                    <Typography variant='h4' color='white'>
                        Continuous Support
                    </Typography>
                    <Typography variant='small' className='text-justify text-white'>
                        Our commitment doesn't end with implementation. We provide ongoing support,
                        training, and optimization services to ensure long-term success.
                    </Typography>
                </Card>
            </div>
        </div>
    )
}
