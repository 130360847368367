import {Carousel, Typography, Button} from "@material-tailwind/react";

export function CarouselWithContent({homeRef, scrollToContactUs, scrollServices}) {

    const handleClickContact = () => {
        if (typeof scrollToContactUs === 'function') {
            scrollToContactUs();
        }
    };

    const handleClickServices = () => {
        if (typeof scrollServices === 'function') {
            scrollServices();
        }
    };

    return (
        <section className="relative hidden max-h-screen sm:block" ref={homeRef}>
            <Carousel
                // autoplay
                loop
                className="rounded-xl max-h-screen"
                navigation={({setActiveIndex, activeIndex, length}) => (
                <div className="absolute bottom-4 left-2/4 z-50 flex -translate-x-2/4 gap-2">
                    {new Array(length)
                        .fill("")
                        .map((_, i) => (<span
                            key={i}
                            className={`block h-1 cursor-pointer rounded-2xl transition-all content-[''] ${activeIndex === i
                            ? "w-8 bg-primary"
                            : "w-4 bg-white"}`}
                            onClick={() => setActiveIndex(i)}/>))}
                </div>
            )}>
                <div className="relative max-h-screen w-full">
                    <img
                        src="https://images.pexels.com/photos/196644/pexels-photo-196644.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                        alt="img 1"
                        className="w-full max-h-screen h-full object-cover"/>
                    <div
                        className="absolute inset-0 grid h-full w-full place-items-center bg-black/75">
                        <div className="w-3/4 text-center md:w-2/4">
                            <Typography
                                variant="h1"
                                color="white"
                                className="mb-4 text-3xl md:text-2xl lg:text-5xl text-primary font-primary">
                                Getting Back to the Core of Consulting: Reaping Trust with Talent and Transparency
                            </Typography>
                            <Typography
                                color="white"
                                className="mb-12 md:text-md lg:text-lg opacity-80 font-secondary">
                                We are 365Labs, Inc. - Your Trusted Partner for Digital Transformation in the Philippines.
                            </Typography>
                            <div className="flex justify-center gap-2">
                                <Button
                                    size="lg"
                                    className="bg-primary font-secondary"
                                    onClick={handleClickServices}>
                                    Explore
                                </Button>
                                <Button
                                    size="lg"
                                    color="white"
                                    className="font-secondary"
                                    variant="text"
                                    onClick={handleClickContact}>
                                    Contact Us
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="relative max-h-screen w-full">
                    <img
                        src="https://images.pexels.com/photos/196644/pexels-photo-196644.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                        alt="img 1"
                        className="w-full max-h-screen h-full object-cover"/>
                    <div
                        className="absolute inset-0 grid h-full w-full place-items-center bg-black/75">
                        <div className="w-3/4 text-center md:w-2/4">
                            <Typography
                                variant="h1"
                                color="white"
                                className="mb-4 text-3xl md:text-2xl lg:text-5xl text-primary font-primary">
                                Elevate Your Retail Experience with LS Retail
                            </Typography>
                            <Typography
                                color="white"
                                className="mb-12 md:text-md lg:text-lg opacity-80 font-secondary">
                                From POS systems to sales analysis tools, LS Retail offers a comprehensive
                                solution tailored to streamline operations and enhance customer experiences
                                across retail industries. Learn how our robust features can optimize your
                                business.
                            </Typography>
                            <div className="flex justify-center gap-2">
                                <Button
                                    size="lg"
                                    className="bg-primary font-secondary"
                                    onClick={handleClickServices}>
                                    Explore
                                </Button>
                                <Button
                                    size="lg"
                                    color="white"
                                    className="font-secondary"
                                    variant="text"
                                    onClick={handleClickContact}>
                                    Contact Us
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="relative h-full w-full max-h-screen">
                    <img
                        src="https://images.pexels.com/photos/196645/pexels-photo-196645.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                        alt="img 2"
                        className="h-full w-full max-h-screen object-cover"/>
                    <div className="absolute inset-0 grid h-full w-full items-center bg-black/75">
                        <div className="w-3/4 pl-12 md:w-2/4 md:pl-20 lg:pl-32">
                            <Typography
                                variant="h1"
                                color="white"
                                className="mb-4 text-3xl md:text-2xl lg:text-5xl text-primary  font-primary">
                                Empower SMBs with LS Express
                            </Typography>
                            <Typography
                                color="white"
                                className="mb-12 md:text-md lg:text-lg opacity-80 font-secondary">
                                LS Express provides essential retail management functionalities in a
                                user-friendly interface, ideal for small and medium-sized businesses. Simplify
                                inventory management, POS operations, and sales reporting with our
                                cost-effective solution designed for efficiency.
                            </Typography>
                            <div className="flex justify-start gap-2">
                                <Button
                                    size="lg"
                                    className="bg-primary font-secondary"
                                    onClick={handleClickServices}>
                                    Explore
                                </Button>
                                <Button
                                    size="lg"
                                    color="white"
                                    className="font-secondary"
                                    variant="text"
                                    onClick={handleClickContact}>
                                    Contact Us
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="relative h-full w-full max-h-screen">
                    <img
                        src="https://images.pexels.com/photos/196646/pexels-photo-196646.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                        alt="img 3"
                        className="h-full w-full max-h-screen object-cover"/>
                    <div className="absolute inset-0 flex items-end justify-end bg-black/75">
                        <div className="w-3/4 pr-12 pb-12 md:w-2/4 md:pr-20 md:pb-20 lg:pl-32 lg:pb-32">
                            <Typography
                                variant="h1"
                                color="white"
                                className="mb-4 text-xl lg:text-5xl text-primary  font-primary">
                                Optimize Your Business Processes with Dynamics 365 Business Central
                            </Typography>
                            <Typography
                                color="white"
                                className="mb-12 md:text-sm lg:text-lg opacity-80 font-secondary">
                                Dynamics 365 Business Central offers a cloud-based ERP solution, empowering
                                businesses with modules for financial management, supply chain management,
                                sales, and more. Explore how our customizable platform can streamline your
                                operations.
                            </Typography>
                            <div className="flex justify-end gap-2">
                                <Button
                                    size="lg"
                                    className="bg-primary font-secondary"
                                    onClick={handleClickServices}>
                                    Explore
                                </Button>
                                <Button
                                    size="lg"
                                    color="white"
                                    className="font-secondary"
                                    variant="text"
                                    onClick={handleClickContact}>
                                    Contact Us
                                </Button>
                            </div>
                        </div>
                    </div>

                </div>
                <div className="relative h-full w-full max-h-screen">
                    <img
                        src="https://images.pexels.com/photos/326503/pexels-photo-326503.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                        alt="img 3"
                        className="h-full w-full max-h-screen object-cover"/>
                    <div className="absolute inset-0 grid h-full w-full items-end bg-black/75">
                        <div className="w-3/4 pl-12 pb-12 md:w-2/4 md:pl-20 md:pb-20 lg:pl-32 lg:pb-32">
                            <Typography
                                variant="h1"
                                color="white"
                                className="mb-4 text-xl lg:text-5xl text-primary  font-primary">
                                Streamline Complex Operations with Dynamics 365 Finance and Operations
                            </Typography>
                            <Typography
                                color="white"
                                className="mb-12 text-sm lg:text-lg opacity-80 font-secondary">
                                Designed for large organizations with intricate operational needs, Dynamics 365
                                Finance and Operations provides comprehensive financial management, supply chain
                                optimization, and human resources solutions. Discover how our scalable platform
                                drives operational excellence.
                            </Typography>
                            <div className="flex justify-start gap-2">
                                <Button
                                    size="lg"
                                    className="bg-primary font-secondary"
                                    onClick={handleClickServices}>
                                    Explore
                                </Button>
                                <Button
                                    size="lg"
                                    color="white"
                                    className="font-secondary"
                                    variant="text"
                                    onClick={handleClickContact}>
                                    Contact Us
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="relative h-full w-full max-h-screen">
                    <img
                        src="https://images.pexels.com/photos/270408/pexels-photo-270408.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                        alt="img 3"
                        className="h-full w-full max-h-screen object-cover"/>
                    <div
                        className="absolute inset-0 grid h-full w-full place-items-center bg-black/75">
                        <div className="w-3/4 text-center md:w-2/4">
                            <Typography
                                variant="h1"
                                color="white"
                                className="mb-4 text-3xl md:text-2xl lg:text-5xl text-primary  font-primary">
                                Unlock Growth Potential with Our Partnership Opportunities
                            </Typography>
                            <Typography
                                color="white"
                                className="mb-12 md:text-md lg:text-lg opacity-80 font-secondary">
                                Collaborate with LS Retail and Dynamics 365 to access scalable solutions and
                                customizable options tailored to your business needs. Explore partnership
                                opportunities that drive efficiency, scale, and innovation in your industry.
                            </Typography>
                            <div className="flex justify-center gap-2">
                                <Button
                                    size="lg"
                                    className="bg-primary font-secondary"
                                    onClick={handleClickServices}>
                                    Explore
                                </Button>
                                <Button
                                    size="lg"
                                    color="white"
                                    className="font-secondary"
                                    variant="text"
                                    onClick={handleClickContact}>
                                    Contact Us
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </Carousel>
        </section>
    );
}